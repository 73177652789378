var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-2" }, [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "div",
            {
              staticClass: "btn-group-vertical buttons",
              attrs: { role: "group", "aria-label": "Basic example" },
            },
            [
              _c(
                "button",
                { staticClass: "btn btn-secondary", on: { click: _vm.add } },
                [_vm._v("Add")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  on: { click: _vm.replace },
                },
                [_vm._v("Replace")]
              ),
            ]
          ),
          _c("div", { staticClass: "form-check" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.enabled,
                  expression: "enabled",
                },
              ],
              staticClass: "form-check-input",
              attrs: { id: "disabled", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.enabled)
                  ? _vm._i(_vm.enabled, null) > -1
                  : _vm.enabled,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.enabled,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.enabled = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.enabled = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.enabled = $$c
                  }
                },
              },
            }),
            _c(
              "label",
              { staticClass: "form-check-label", attrs: { for: "disabled" } },
              [_vm._v("DnD enabled")]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("h3", [_vm._v("Draggable " + _vm._s(_vm.draggingInfo))]),
          _c(
            "draggable",
            {
              staticClass: "list-group",
              attrs: {
                list: _vm.list,
                disabled: !_vm.enabled,
                "ghost-class": "ghost",
                move: _vm.checkMove,
              },
              on: {
                start: function ($event) {
                  _vm.dragging = true
                },
                end: function ($event) {
                  _vm.dragging = false
                },
              },
            },
            _vm._l(_vm.list, function (element) {
              return _c(
                "div",
                { key: element.name, staticClass: "list-group-item" },
                [_vm._v("\n        " + _vm._s(element.name) + "\n      ")]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("rawDisplayer", {
        staticClass: "col-3",
        attrs: { value: _vm.list, title: "List" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }